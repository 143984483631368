import React, { useEffect } from 'react';
import ProductLayout from '../../layouts/ProductLayout/ProductLayout';
import AppLayout from '../../layouts/appLayout/appLayout';
import ProductComposition from './Components/ProductComposition/ProductComposition';
import ProductExperience from './Components/ProductExperience/ProductExperience';
import ProductOptionsQTY from './Components/ProductOptionsQTY/ProductOptionsQTY';
import ProductThumbnail from './Components/ProductThumbnail/ProductThumbnail';
// redux
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { NavigationType } from '../../consts/app_enums';
import useFetchProductData from '../../hooks/useFetchProductData';
import { selectErrorState, selectLoadingState, selectProductData } from '../../store/productDetails/selectors/productSelectors';
import ProductDetailsShimmer from './Components/ProductDetailsShimmer/ProductDetailsShimmer';
import ProductNotAnalyzed from './Components/ProductNotAnalyzed/ProductNotAnalyzed';
import RecommendedProducts from './Components/RecommendedProducts/RecommendedProducts';

import CartDialog from '../../components/cartTypeDialog/cartTypeDialog';
import AgeVerification from '../AgeVerification/AgeVerification';
import useAnalytics from '../../useAnalytics';
import mixpanel from 'mixpanel-browser/src/loaders/loader-module-core';
import { useFilterSelection } from '../../hooks/useFilterSelection';

export default function ProductDetails() {
  useAnalytics('page_view', { page_title: 'Product Details' });
  const productData = useSelector(selectProductData);
  const loading = useSelector(selectLoadingState);
  const error = useSelector(selectErrorState);
  const { id } = useParams();
  useFetchProductData(id);

  const { handleResetFilters } = useFilterSelection();
  const userVerfificationState = useSelector((state) => state.user.userVerified);

  useEffect(() => {
    let isSubscribed = true;

    const trackProductViewed = async () => {
      try {
        mixpanel.track('Product Viewed', {
          id: productData?.productId,
          ...(productData?.thumbnail ?? {}),
          ...(productData?.experience ?? {}),
          ...(productData?.composition ?? {}),
          url: window.location.href
        });
      } catch (error) {
        console.log(error);
      }
    };

    if (isSubscribed) trackProductViewed();

    return () => {
      isSubscribed = false;
    };
  }, []);

  useEffect(() => {
    // handleResetFilters(); // Reset previous products page search filters
  }, []);

  return (
    <>
      {loading ? (
        <ProductDetailsShimmer />
      ) : error ? (
        <Typography>Error: {error}</Typography>
      ) : productData ? (
        <>
          {!userVerfificationState && <AgeVerification isProductDetail={true} />}

          {productData.isPowerleafAnalyzed ? (
            <AppLayout version={NavigationType.V2}>
              <ProductLayout
                thumbnail={
                  <ProductThumbnail
                    thumbnailData={productData.thumbnail}
                    productType={productData?.composition?.cannabinoids?.productType}
                  />
                }
                experience={<ProductExperience experienceData={productData?.experience} />}
                optionsQTY={<ProductOptionsQTY quantitySelection={productData?.quantitySelection} />}
                composition={<ProductComposition compositionData={productData?.composition} />}
                recommended={
                  <RecommendedProducts
                    title="Discover Similar Items"
                    products={productData?.recommended}
                    isAnalyzed={productData?.isPowerleafAnalyzed}
                  />
                }
              />

              <CartDialog />
            </AppLayout>
          ) : (
            <AppLayout version={NavigationType.V2}>
              <ProductLayout
                thumbnail={<ProductThumbnail thumbnailData={productData?.thumbnail} />}
                optionsQTY={<ProductOptionsQTY quantitySelection={productData?.quantitySelection} />}
                experience={
                  <ProductNotAnalyzed
                    cannabinoidsNotAnalyzed={productData?.cannabinoidsNotAnalyzed}
                    terpenesNotAnalyzed={productData?.terpenesNotAnalyzed}
                  />
                }
                // recommended={
                //   <RecommendedProducts
                //     title="Discover Similar Items"
                //     products={productData.recommended}
                //     isAnalyzed={productData.isPowerleafAnalyzed}
                //   />
                // }
              />

              <CartDialog />
            </AppLayout>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
