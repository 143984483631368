import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, Grid, IconButton, Paper, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BackgroundImage from '../../assets/icons/ageVerification/background.svg';
import { verifyUserPhoneNumber } from '../../network/services/userVerificationService';
import { appRoutes } from '../../routes/appRoutes';
import { updateFiltersAction } from '../../store/actions/shopByActions';
import { selectSelectedFilters } from '../../store/selectors/shopBySelectors';

import {
  StyledButton,
  StyledLogo,
  StyledTypography,
  StyledTypographyBody,
  StyledTypographyBodyQuestion,
  StyledTypographyHelperText
} from './styledComponent';

import { verifyUserAction } from '../../store/actions/userActions';
import mixpanel from 'mixpanel-browser/src/loaders/loader-module-core';

const WelcomeComponent = ({ setStep, isProductDetail = false }) => {
  // Navigate
  const navigate = useNavigate();

  // Dispatch
  const dispatch = useDispatch();

  // Selectors
  const userVerfificationState = useSelector((state) => state.user.userVerified);

  useEffect(() => {
    if (userVerfificationState) {
      navigate(appRoutes.home);
    }
  });

  const handleShoppingNavigate = async () => {
    const sessionID = getRandomString(12);
    // setDisabled(true);

    // if (phoneNumber) {
    //   try {
    //     console.log('Mixpanel identify', phoneNumber);
    //     mixpanel.identify(phoneNumber);
    //     mixpanel.start_session_recording();
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    try {
      // const response = await verifyUserPhoneNumber(phoneNumber);
      // console.log(response);
      var userType = 'adult';
      var newFilters = {
        ...filtersState
      };

      if (response?.data?.user?.isMedicalUser) {
        // console.log("User is a Medical User");
        userType = 'medical';
      }

      newFilters = {
        ...newFilters,
        'Purchase Type': userType
      };

      dispatch(verifyUserAction(response?.data?.user, response?.data?.user?.phoneNumber, false, userType));
      dispatch(updateFiltersAction(newFilters));

      try {
        console.log('Mixpanel identify', phoneNumber);
        mixpanel.identify(`session-id-${sessionID}`);
        mixpanel.start_session_recording();
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      // NotificationToaster({ message: error?.response?.data?.message, type: "error" });
      dispatch(verifyUserAction(null, '', true, 'adult'));
      console.log(error);
    } finally {
      if (isProductDetail) {
        // setDialogOpen(false);
      } else {
        // setDisabled(false);
        navigate(appRoutes.home);
      }
    }
  };

  return (
    <>
      {!isProductDetail && <StyledLogo />}

      <Paper
        elevation={isProductDetail ? 0 : 3}
        sx={{
          marginTop: '3%',
          padding: 2,
          width: { xs: '90%', sm: '486px' },
          height: 256,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <StyledTypography>Welcome!</StyledTypography>

        <StyledTypographyBody>To explore our shopping experience,</StyledTypographyBody>
        <StyledTypographyBody>you must be 21 years of age.</StyledTypographyBody>

        <StyledTypographyBodyQuestion>Are you over 21 years of age?</StyledTypographyBodyQuestion>

        <Grid container spacing={2} justifyContent={'space-between'}>
          <Grid item xs={6}>
            <StyledButton onClick={handleShoppingNavigate}>YES</StyledButton>
          </Grid>

          <Grid item xs={6}>
            <StyledButton
              onClick={() => {
                setStep(0);
              }}
            >
              NO
            </StyledButton>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

const SorryComponent = ({ setStep, isProductDetail = false }) => {
  return (
    <>
      {!isProductDetail && <StyledLogo />}

      <Paper
        elevation={isProductDetail ? 0 : 3}
        sx={{
          position: 'relative',
          marginTop: '0%',
          padding: 2,
          width: { xs: '90%', sm: '486px' },
          height: 172,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            padding: '10px'
          }}
        >
          <IconButton
            color="inherit"
            style={{ outline: 'none' }}
            sx={{
              '&:hover': {
                backgroundColor: '#fff'
              }
            }}
            onClick={() => {
              setStep(1);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <StyledTypography>We&apos;re Sorry</StyledTypography>

        <StyledTypographyBody textAlign="center">You must be 21 years of age or older to visit our website.</StyledTypographyBody>
      </Paper>
    </>
  );
};

const PhoneNumberComponent = ({ setStep, isProductDetail = false, setDialogOpen }) => {
  // Dispatch
  const dispatch = useDispatch();

  // Selectors
  const userVerfificationState = useSelector((state) => state.user.userVerified);
  const filtersState = useSelector(selectSelectedFilters);

  // Navigate
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState(null);
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(true);

  // Handlers
  const handleShoppingNavigate = async () => {
    setDisabled(true);

    if (phoneNumber) {
      try {
        console.log('Mixpanel identify', phoneNumber);
        mixpanel.identify(phoneNumber);
        mixpanel.start_session_recording();
      } catch (error) {
        console.log(error);
      }
    }
    try {
      const response = await verifyUserPhoneNumber(phoneNumber);
      // console.log(response);
      var userType = 'adult';
      var newFilters = {
        ...filtersState
      };

      if (response?.data?.user?.isMedicalUser) {
        // console.log("User is a Medical User");
        userType = 'medical';
      }

      newFilters = {
        ...newFilters,
        'Purchase Type': userType
      };

      dispatch(verifyUserAction(response?.data?.user, response?.data?.user?.phoneNumber, false, userType));
      dispatch(updateFiltersAction(newFilters));
    } catch (error) {
      // NotificationToaster({ message: error?.response?.data?.message, type: "error" });
      dispatch(verifyUserAction(null, phoneNumber, true, 'adult'));
      console.log(error);
    } finally {
      if (isProductDetail) {
        setDialogOpen(false);
      } else {
        setDisabled(false);
        navigate(appRoutes.home);
      }
    }
  };

  const formatPhoneNumber = (numericPhoneNumber) => {
    const regex = /^(\d{3})(\d{3})(\d{4})$/;
    const matches = numericPhoneNumber.match(regex);

    if (matches) {
      return `(${matches[1]}) ${matches[2]}-${matches[3]}`;
    }

    return numericPhoneNumber;
  };

  const onChangeHandler = (e) => {
    if (e.target.value.length === 10) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    const inputNumeric = e.target.value.replace(/\D/g, '');
    const formattedPhoneNumber = formatPhoneNumber(inputNumeric);
    setPhoneNumber(formattedPhoneNumber);
  };

  useEffect(() => {});

  return (
    <>
      {!isProductDetail && <StyledLogo />}

      <Paper
        elevation={isProductDetail ? 0 : 3}
        sx={{ marginTop: isProductDetail ? '0%' : '3%', padding: 2, width: { xs: '90%', sm: '486px' }, height: 390 }}
      >
        <IconButton
          color="inherit"
          style={{ outline: 'none' }}
          sx={{
            '&:hover': {
              backgroundColor: '#fff'
            }
          }}
          onClick={() => {
            setStep(1);
          }}
        >
          <ArrowBackIcon />
          Back
        </IconButton>

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <StyledTypography>Excellent!</StyledTypography>

          <StyledTypographyBody>Please provide your phone number below to access</StyledTypographyBody>
          <StyledTypographyBody>shopping platform and receive updates on your order.</StyledTypographyBody>

          <TextField
            label="Phone Number"
            placeholder="(___) ___ - ____"
            style={{ width: '90%', marginTop: '4%', marginBottom: '6%' }}
            onChange={onChangeHandler}
            value={phoneNumber}
            error={error}
            inputProps={{ maxLength: 10 }}
          />

          <Grid container spacing={2} justifyContent={'center'}>
            <Grid item xs={11}>
              <StyledButton onClick={handleShoppingNavigate} disabled={disabled}>
                Start Shopping
              </StyledButton>
            </Grid>
          </Grid>

          <StyledTypographyHelperText>
            We only use your information for order and marketing purposes. We never share your data with outside companies.
          </StyledTypographyHelperText>
        </Box>
      </Paper>
    </>
  );
};

const AgeVerification = ({ isProductDetail = false }) => {
  const [step, setStep] = useState(1);
  const [dialogOpen, setDialogOpen] = useState(isProductDetail);

  return (
    <>
      {isProductDetail ? (
        <>
          <Dialog
            open={dialogOpen}
            sx={{
              '& .MuiDialog-container': {
                '& .MuiPaper-root': {
                  width: '100%',
                  maxWidth: '500px' // Set your width here
                }
              }
            }}
            fullWidth
          >
            {step === 0 ? (
              <SorryComponent setStep={setStep} isProductDetail={isProductDetail} />
            ) : step === 1 ? (
              <WelcomeComponent setStep={setStep} isProductDetail={isProductDetail} />
            ) : (
              <PhoneNumberComponent setStep={setStep} isProductDetail={isProductDetail} setDialogOpen={setDialogOpen} />
            )}
          </Dialog>
        </>
      ) : (
        <>
          <Box
            sx={{
              backgroundImage: `url(${BackgroundImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              // backgroundSize: '100vw 100vh',
              height: '100vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              '&::after': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'rgba(0, 0, 0, 0.65)',
                mixBlendMode: 'multiply',
                pointerEvents: 'none',
                zIndex: -1,
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center'
              }
            }}
          >
            {step === 0 ? (
              <SorryComponent setStep={setStep} />
            ) : step === 1 ? (
              <WelcomeComponent setStep={setStep} />
            ) : (
              <PhoneNumberComponent setStep={setStep} />
            )}
          </Box>
        </>
      )}
    </>
  );
};

function getRandomString(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  return result;
}

export default AgeVerification;
